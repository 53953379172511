<template>
  <Page>
    <div class="home-page">
      <zui-header/>
      <div class="content">
        <div class="logo-box">
          <img src="https://www.achencybe.com/img/pc/logo.png">
        </div>
        <div class="code-box">
          <div style="margin-top:-30px;font-size:30px;">{{ $t('form_title_1') }}</div>
          <div style="font-size:18px;margin-top:5px;font-weight:100">{{ $t('form_title_2') }}</div>
        </div>
      </div>
      <zui-footer/>
    </div>
  </Page>
</template>

<script>

import Page from "../../../components/Page";
import ZuiHeader from "../../../components/Header";
import ZuiFooter from "../../../components/Footer";

export default {
  name: "InvestorsFormResultPage",
  components: {Page, ZuiHeader, ZuiFooter},
};
</script>

<style scoped lang="scss">
.content {
  width: 54%;
  margin: 200px auto 200px;
  box-shadow: #0c0c0c -5px 9px 17px 4px;

  .logo-box {
    width: 100%;
    padding: 100px 0;
    background-color: #121212;

    img {
      width: 20%;
    }
  }

  .code-box {
    background-color: #fff;
    width: 100%;
    padding: 3% 0;
    height: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>